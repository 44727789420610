import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"400\",\"600\",\"700\"],\"subsets\":[\"latin\"]}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/tailwindcss/tailwind.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Analytics/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/PrototypePin/PrototypePin.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/global.sass");
