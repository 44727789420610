'use client';

import useLocalStorage from '@/hooks/useLocalStorage';
import usePrototypePin from '@/hooks/usePrototypePin';
import isProduction from '@/utils/isProduction';
import { Dialog } from '@headlessui/react';
import { useEffect, useState } from 'react';

const PrototypePin = () => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    const [lastPrototypePin, setLastPrototypePin] = useLocalStorage<string>(
        'lastPrototypePin',
        '',
    );

    const isLocalHost =
        typeof location !== 'undefined' && location?.hostname === 'localhost';
    const isPinEnabled = isClient && !isLocalHost && !isProduction();
    const isCorrectPin =
        !isPinEnabled || lastPrototypePin === usePrototypePin();

    useEffect(() => {
        if (!isCorrectPin) {
            const main = document.querySelector('main');
            if (!main) return;

            // Set inert attribute on main element
            main.setAttribute('inert', '');
            // Set opacity of main element to 0 and pointer events to none
            main.style.opacity = '0';
            main.style.pointerEvents = 'none';
        }
    }, [isCorrectPin]);

    if (!isPinEnabled) return null;

    return (
        <Dialog
            open={!isCorrectPin}
            onClose={() => alert('Prototype werkt alleen met pin')}
            className="fixed inset-0 bg-white p-12 z-200"
        >
            <Dialog.Title>Prototype Pin</Dialog.Title>
            <input
                type="password"
                value={lastPrototypePin}
                onChange={(event) => {
                    setLastPrototypePin(event.target.value);
                    if (event.target.value === usePrototypePin()) {
                        location.reload();
                    }
                }}
            />
        </Dialog>
    );
};

export default PrototypePin;
