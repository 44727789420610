'use client';

const usePrototypePin = () => {
    // Get the current day and month
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;

    // Add 5 to day, multiply by 6 and get 2 numbers
    const firstValue = ((day + 5) * 6).toString().substring(0, 2);

    // Add 74 to month, multiply by 3 and get 2 numbers
    const secondValue = ((month + 74) * 3).toString().substring(1, 3);

    // Use first number of first value, then second number of second value, then second number of first value
    return `${firstValue[0]}${secondValue}${firstValue[1]}`;
};

export default usePrototypePin;
